import React from 'react';
import { createSwitchNavigator } from '@react-navigation/core';
import { createBrowserApp } from '@react-navigation/web';
import AppSwitchPage from './AppSwitchPage';
import loginRoutes from './login/routes';
import photoRoutes from './photo/routes';

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
}

const setScreenProps = (Component, propsValue) =>
  class extends Component {
    render() {
      return <Component {...this.props} screenProps={propsValue} />;
    }
  };

function convertRoutes(routes) {
  let newRoutes = {};
  for (let routeName in routes) {
    let route = routes[routeName];
    if (!(route instanceof Object)) {
      continue;
    }
    newRoutes[routeName] = {
      screen: setScreenProps(route.page, route.screenProps),
      path: route.path,
      navigationOptions: {
        title: 'WonderLife - ' + route.title
      }
    };
  }
  return newRoutes;
}

const AppRoute = createBrowserApp(createSwitchNavigator({
  AppSwitchPage,
  ...convertRoutes(loginRoutes),
  ...convertRoutes(photoRoutes)
}, {
  initialRouteName: 'AppSwitchPage'
}));
export default AppRoute;

export const mainRoutes = [
  photoRoutes
];