import Redux from '@src/redux/Redux';

export default class User extends Redux {
  static getInstance() {
    if (!this._instance) {
      this._instance = new User();
    }
    return this._instance;
  }

  static type = 'User';
  static valueField = 'userDTO';
  static getReduxInfo() {
    if (!this._reduxInfo) {
      this._reduxInfo = Redux.createReduxInfo(this.type, this.valueField, {
        name: '',
        avatarDTO: {},
        roleDTOList: [],
        groupDTOList: []
      });
    }
    return this._reduxInfo;
  }

  constructor() {
    super(User.type, User.valueField, User.getReduxInfo());
  }

  static getAvatarUrl(avatarDTO) {
    return null;
  }

  static getAvatarThumbUrl(avatarDTO) {
   return null; 
  }

  async fetch(isDeep) {
    super._fetchStart();
    const rsp = {code: -1, msg: 'invalid login'};
    if (rsp.code == 0) {
      super._fetched(rsp.data);
    } else {
      super._failed(rsp.msg);
    }
  }

  async update(userDTO) {
    super._updateStart();
    const rsp = {code: -1, msg: 'invalid login'};
    if (rsp.code == 0) {
      super._updated(userDTO);
    } else {
      super._failed(rsp.msg);
    }
  }
}