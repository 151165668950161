import React, { Component, Text } from 'react';
import { connect } from 'react-redux';
import { LocaleProvider, message } from 'antd'
import Auth from '@src/model/Auth';

import AppRoute from './AppRoute';

class RootPage extends Component {
  constructor() {
    super();
  }

  shouldComponentUpdate(nextProps, nextState) {
    let {isLoggedIn} = nextProps;

    if (isLoggedIn == this.props.isLoggedIn) {
      return false;
    }

    if (isLoggedIn) {
      message.success('登录成功', 4);
    }

    return true;
  }

  render() {
    return (
        <AppRoute ref={ref => {this.navigator = ref}}/>);
  }
}

function mapState2Props(state) {
  return {
    isLoggedIn: Auth.getInstance().isLoggedIn
  };
}

export default connect(mapState2Props)(RootPage);
