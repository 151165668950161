import createActionsAndReducer from './acts/createActionsAndReducer';
import Store from './Store';
import DateFormatUtil from '@src/utils/DateFormatUtil';

const VALUE_EXPIRE_TIME = 15 * 60 * 1000;  //15mins

export default class Redux {
  static createReduxInfo(type, valueField, initialValue, isMultiple = false) {
    return createActionsAndReducer(type, valueField, initialValue, isMultiple);
  }

  constructor(type, valueField, reduxInfo, instanceId = null) {
    this._type = type;
    this._valueField = valueField;
    this._reduxInfo = reduxInfo;
    this._instanceId = instanceId;
  }

  get value() {
    let valueField = this._valueField;
    let valueObj = this._getValueObj();
    let result = null;

    if (this._instanceId) {
      let instanceValueObj = valueObj[this._instanceId];
      result = instanceValueObj ? instanceValueObj[valueField] : null;
      if (!result) {
        result = this._reduxInfo.initialValue;
      }
    } else {
      result = valueObj[valueField];
    }

    if (!result) {
      result = this._reduxInfo.initialValue;
    }

    // deep clone result.
    return JSON.parse(JSON.stringify(result), DateFormatUtil.dateParser);
  }

  get isFetching() {
    let valueObj = this._getValueObj();

    if (this._instanceId) {
      return valueObj.isFetching ? !!valueObj.isFetching[this._instanceId] : false;
    } else {
      return valueObj.isFetching || false;
    }
  }

  get isUpdating() {
    let valueObj = this._getValueObj();

    if (this._instanceId) {
      return valueObj.isUpdating ? !!valueObj.isUpdating[this._instanceId] : false;
    } else {
      return valueObj.isUpdating || false;
    }
  }

  get error() {
    let valueObj = this._getValueObj();

    if (this._instanceId) {
      return valueObj.error ? valueObj.error[this._instanceId] : null;
    } else {
      return valueObj.error || null;
    }
  }

  fetchIfExpired(...args) {
    if (this._checkExpired()) {
      this.fetch(...args);
    }
  }

  _getValueObj() {
    return Store.getInstance().state[this._type];
  }

  _fetchStart() {
    this._reduxInfo.actions.fetchStart(null, this._instanceId);
  }

  _fetched(value) {
    this._reduxInfo.actions.fetched(value, this._instanceId);
  }

  _updateStart() {
    this._reduxInfo.actions.updateStart(null, this._instanceId); 
  }

  _updated(value) {
    this._reduxInfo.actions.updated(value, this._instanceId);
  }

  _failed(methodName, msg) {
    let errMsg = `[${this._type}.${methodName}]${msg}`;
    this._reduxInfo.actions.failed(errMsg, this._instanceId);
  }

  expired() {
    this._reduxInfo.actions.expired(null, this._instanceId);
  }

  _checkExpired() {
    let lastFetchTime = null;
    if (this._instanceId) {
      let instanceValueObj = this._getValueObj()[this._instanceId];
      lastFetchTime = instanceValueObj ? instanceValueObj.lastFetchTime : null;
    } else {
      lastFetchTime = this._getValueObj().lastFetchTime;
    }
    
    if (lastFetchTime != null && new Date().getTime() - lastFetchTime < VALUE_EXPIRE_TIME) {
      return false;
    }
    return true;
  }
}