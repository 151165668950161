import React, { Component } from 'react';
import { message } from 'antd';

export default class ReactComponent extends Component {
  constructor(props, pendingParams = {}) {
    super(props);
    this._pendingMap = {};
    for (let pendingKey in pendingParams) {
      let successMsg = pendingParams[pendingKey];
      this._pendingMap[pendingKey] = {
        successMsg: successMsg,
        pending: false
      };
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps.value) != JSON.stringify(this.props.value)) {
      if (nextProps.value.error) {
        message.error(nextProps.value.error, 2);
        for (let pendingKey in this._pendingMap) {
          let pendingInfo = this._pendingMap[pendingKey];
          pendingInfo.pending = false;
        }
        return true;
      }

      for (let pendingKey in this._pendingMap) {
        let pendingInfo = this._pendingMap[pendingKey];
        if (pendingInfo.pending && !nextProps.value.isUpdating) {
          message.success(pendingInfo.successMsg);
          pendingInfo.pending = false;
        }
      }
      return true;
    }

    if (this.shouldUpdate) {
      return this.shouldUpdate(nextProps, nextState);
    } else {
      if (JSON.stringify(nextState) != JSON.stringify(this.state)) {
        return true;
      }  
    }

    return false;
  }

  isPending(key) {
    return this._pendingMap[key].pending;
  }

  doPend(key) {
    this._pendingMap[key].pending = true;
  }
}