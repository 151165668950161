import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import { assignAll } from 'redux-act';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

const logger = store => next => action => {
  if(typeof action === 'function') console.log('dispatching a function');
  else console.log('dispatching', action);
  let result = next(action);
  console.log('next state', store.getState());
  return result;
}

export default function configureStore(actionMap, reducers, initialState, onComplete: ()=>void) {
  let middlewares = [logger, thunk];

  const createAppStore = compose(
    applyMiddleware(...middlewares)
  )(createStore);
  const store = createAppStore(combineReducers(reducers), initialState);
  actionMap.map((actions) => {
    assignAll(actions, store);  
  });
  let persistor = persistStore(store, null, onComplete);
  return {store, persistor};
}
