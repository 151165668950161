import React, { Component } from 'react';
import { Provider } from 'react-redux';
import RootPage from './pages/RootPage';
import Auth from './model/Auth';
import Store from './redux/Store';
import * as models from './model';

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      isInitializing: true
    }
    this.initStore();
  }

  async initStore() {
    const isLoggedIn = await Auth.getInstance().checkAuth();
    const initialState = {
      Auth: { isLoggedIn: isLoggedIn }
    };
    Store.getInstance().init(models, initialState, () => {
      this.setState({isInitializing: false});
    });
  }

  render() {
    if(this.state.isInitializing){
      return null;
    }

    return (
      <Provider store={Store.getInstance().store}>
        <RootPage />
      </Provider>
    )
  }
}
