import React from 'react';
import { Button, Modal } from 'antd';

class ModalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
  }

  _onBtnClick = () => {
    if (this.props.onShowModal) {
      this.props.onShowModal();
    }

    this.setState({
      visible: true
    });
  }

  _onModalClose = () => {
    if (this.props.onHideModal) {
      this.props.onHideModal();
    }
  }

  _onModalOK = (e) => {
    if (this.props.onOK) {
      this.props.onOK();
    }

    this.setState({
      visible: false,
    });
  }

  _onModalCancel = (e) => {
    this.setState({
      visible: false,
    });
  }

  render() {
    return (
      <div className={this.props.className}>
        <Button type="primary" onClick={this._onBtnClick} loading={this.props.loading} disabled={this.props.disabled}>{this.props.title}</Button>
        <Modal
          title={this.props.title}
          visible={this.state.visible}
          onOk={this._onModalOK}
          onCancel={this._onModalCancel}
          afterClose={this._onModalClose}
        >
          {this.props.children}
        </Modal>
      </div>
    );
  }
}

export default ModalButton;
