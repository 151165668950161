import Redux from '@src/redux/Redux';
import { mainRoutes } from '@src/pages/AppRoute';

export default class BaseSetting extends Redux {
  static getInstance() {
    if (!this._instance) {
      this._instance = new BaseSetting();
    }
    return this._instance;
  }

  static type = 'BaseSetting';
  static valueField = 'setting';
  static isWhiteInReset = true;
  static getReduxInfo() {
    if (!this._reduxInfo) {
      const openKeys = mainRoutes.map(routes => routes.key);
      this._reduxInfo = Redux.createReduxInfo(this.type, this.valueField, {
        collapsed: false,
        openKeys
      });
    }
    return this._reduxInfo;
  }

  constructor() {
    super(BaseSetting.type, BaseSetting.valueField, BaseSetting.getReduxInfo());
  }

  get collapsed() {
    return super.value.collapsed;
  }

  async setCollapsed(collapsed) {
    let value = super.value;
    super._updateStart();
    super._updated({
      ...value,
      collapsed
    });
  }

  get openKeys() {
    return super.value.openKeys;
  }

  async setOpenKeys(openKeys) {
    let value = super.value;
    super._updateStart();
    super._updated({
      ...value,
      openKeys
    });
  }
}