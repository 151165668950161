import React from 'react';
import { connect } from 'react-redux';
import { Button, Layout, Menu, Breadcrumb, Icon, Avatar, Dropdown, message } from 'antd';
import { Link } from "@react-navigation/web";

import { mainRoutes } from '@src/pages/AppRoute';
import BaseSetting from '@src/model/BaseSetting';
import { Auth, User } from '@src/model';
import Assets from '@src/assets';
import styles from './styles/MainLayout.module.css';

const { SubMenu } = Menu;
const { Header, Content, Sider, Footer } = Layout;

class MainLayout extends React.Component {
  constructor(props) {
    super(props);

    let collapsed = BaseSetting.getInstance().collapsed;
    this.state = {
      openKeys: collapsed ? [] : BaseSetting.getInstance().openKeys,
      collapsed: collapsed,
      contentHeight: '100%'
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isLoggedIn } = nextProps;
    if (isLoggedIn != this.props.isLoggedIn) {
      if (!isLoggedIn) {
        const { navigate } = this.props.navigation;
        navigate('Login');
      }
      return false;
    }

    if (JSON.stringify(nextProps.value) != JSON.stringify(this.props.value)) {
      if (nextProps.value.error) {
        message.error(nextProps.value.error, 2);
      }
      return true;
    }
    
    return true;
  }

  componentWillMount() {
    const { isLoggedIn } = this.props;
    if (!isLoggedIn) {
      const { navigate } = this.props.navigation;
      navigate('Login');
      return;
    }

    let { user } = this.props.model;
    user.fetchIfExpired();

    window.addEventListener('resize', this._componentResized);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._componentResized);
  }

  componentDidMount() {
    this._componentResized();
  }

  _onLogoutClicked = () => {
    Auth.getInstance().logout();
  }

  _componentResized = () => {
    let childrenHeight = this.content.clientHeight;

    const headerHeight = 69 + 64 + 55;
    let contentHeight = window.innerHeight - headerHeight;
    if (contentHeight < childrenHeight) {
      contentHeight = '100%';
    }
    this.setState({ contentHeight: contentHeight });
  }

  _onCollapse = (collapsed) => {
    BaseSetting.getInstance().setCollapsed(collapsed);
    if (!collapsed) {
      this.setState({ 
        openKeys: BaseSetting.getInstance().openKeys,
        collapsed
      });
    } else {
      this.setState({ collapsed, openKeys: [] });
    }
  };

  _onMenuOpenChange = (openKeys) => {
    if (!this.state.collapsed) {
      BaseSetting.getInstance().setOpenKeys(openKeys);  
    }
    this.setState({ openKeys });
  };

  _getBreadcrumbByMenuKey = (menuKey) => {
    for (let routes of mainRoutes) {
      for (let routeName in routes) {
        let route = routes[routeName];
        if (!(route instanceof Object)) {
          continue;
        }

        if (route.screenProps.selectedMenu == menuKey) {
          return routes.title + '-' + route.title;
        }
      }
    }
    return null;
  }

  _renderMenuItem = (routeKey, routeName, routeTitle) => {
    return (
      <Menu.Item key={routeKey}>
        <Link routeName={routeName}>{routeTitle}</Link>
      </Menu.Item>
    );
  }

  _renderSubMenu = (routes) => {
    let menuItems = [];
    for (let routeName in routes) {
      let route = routes[routeName];
      if (!(route instanceof Object)) {
        continue;
      }
      menuItems.push(this._renderMenuItem(route.screenProps.selectedMenu, routeName, route.title));
    }

    return (
      <SubMenu key={routes.key} title={<span><Icon type={routes.icon}/><span>{routes.title}</span></span>}>
        {menuItems}
      </SubMenu>
    )
  }

  render() {
    const { collapsed, openKeys, contentHeight } = this.state;
    const { selectedMenu } = this.props.screenProps;
    const { userDTO } = this.props.value;
    const breadcrumbName = this._getBreadcrumbByMenuKey(selectedMenu);

    let avatarUrl = null;
    if (userDTO.avatarDTO) {
      avatarUrl = User.getAvatarThumbUrl(userDTO.avatarDTO); 
    }

    const menu = (
      <Menu>
        <Menu.Item>
          <a onClick={this._onLogoutClicked}>登出</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout>
        <Header className="header">
          <div className={styles.logo}>
            <img className={styles.avatar} src={Assets.logo} />
            <div className={styles.title}>WonderLife</div>
          </div>
          <div className={styles.viewUserInfo}>
            <Avatar src={avatarUrl} size={24} className={styles.avatar} />
            <div className={styles.userName}>
              <Dropdown overlay={menu}>
                <a className="ant-dropdown-link" style={{ color: 'white' }}>
                  {userDTO.name} <Icon type="down" />
                </a>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Layout>
          <Sider width={200} style={{ background: '#fff' }} collapsible collapsed={collapsed} onCollapse={this._onCollapse} >
            <Menu
              mode="inline"
              defaultSelectedKeys={[selectedMenu]}
              style={{ height: '100%' }}
              openKeys={openKeys}
              onOpenChange={this._onMenuOpenChange}
            >
            {
              mainRoutes.map(routes => this._renderSubMenu(routes))
            }
            </Menu>
          </Sider>
          <Layout style={{ padding: '0 24px' }}>
            <Breadcrumb className={styles.breadcrumb}>
              <Breadcrumb.Item><Icon type="home" /></Breadcrumb.Item>
              {
                breadcrumbName.split('-').map((breadcrumb, i) => {
                  return <Breadcrumb.Item key={breadcrumb}>
                    {breadcrumb}
                  </Breadcrumb.Item>
                })
              }
            </Breadcrumb>
            <div style={{height: contentHeight}} ref={node => this.content = node}>
              {this.props.children}
            </div>
            <Footer style={{ textAlign: 'center' }}>
              WonderLife @2020 Created by @Bricks
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

function mapState2Props(state, props) {
  const user = User.getInstance();
  return {
    isLoggedIn: Auth.getInstance().isLoggedIn,
    value: {
      userDTO: user.value,
      refreshing: user.isFetching,
      error: user.error
    },
    model: {
      user
    }
  }
}

export default connect(mapState2Props)(MainLayout);
