import PhotoUploadPage from './PhotoUploadPage';

export default {
  title: '相册',
  icon: 'user',
  key: 'sub_photo',
  PhotoUpload: {
    page: PhotoUploadPage,
    path: 'photo/upload',
    title: '照片上传',
    screenProps: {
      selectedMenu: 'menu_photo_upload'
    }
  }
}
