import React, { Component } from 'react';
import { connect } from 'react-redux';

import Auth from '@src/model/Auth';

class AppSwitchPage extends Component {
  constructor(props) {
    super();

    const { isLoggedIn } = props;
    const { navigate } = props.navigation;
    navigate(isLoggedIn ? 'PhotoUpload' : 'Login');
  }

  render() {
    return null;
  }
}

function mapState2Props(state) {
  return {
    isLoggedIn: Auth.getInstance().isLoggedIn
  };
}

export default connect(mapState2Props)(AppSwitchPage);