export default class DateFormatUtil {
  static dateParser(key, value) {
    let reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    let reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
    
    if (typeof value === 'string') {
      let a = reISO.exec(value);
      if (a) {
          return new Date(value);
      }

      a = reMsAjax.exec(value);
      if (a) {
        let b = a[1].split(/[-+,.]/);
        return new Date(b[0] ? +b[0] : 0 - +b[1]);
      }
    }
    return value;
  };
}