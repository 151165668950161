import {createAction, createReducer} from 'redux-act';
import Store from '@src/redux/Store';
import RandomUtil from '@src/utils/RandomUtil';

const REFRESH_INTERVAL = 2000;
const authActions = {
  start: createAction("auth/start"),
  success: createAction("auth/success"),
  fail: createAction("auth/fail"),
  update: createAction("auth/update")
};

const authReducer = createReducer({
  [authActions.start]: (state) => ({...state, isDoing: true, error: null}),
  [authActions.success]: (state) => ({isLoggedIn: true, isDoing: false, error: null}),
  [authActions.fail]: (state, error) => ({isLoggedIn: false, isDoing: false, error: error}),
  [authActions.update]: (state, isLoggedIn) => ({isLoggedIn: isLoggedIn, isDoing: false, error: null})
}, {
  isLoggedIn: false, 
  isDoing: false,
  error: null
});

export default class Auth {
  static getInstance() {
    if (!this._instance) {
      this._instance = new Auth();
    }
    return this._instance;
  }

  static type = 'Auth';
  static getReduxInfo() {
    return {
      actions: authActions,
      reducer: authReducer
    };
  }

  constructor() {
    this._sessionId = RandomUtil.randomString(32);
  }

  get qrData() {
    return this._qrData;
  }

  get isLoggedIn() {
    return this._getState().Auth.isLoggedIn;
  }

  get isDoing() {
    return this._getState().Auth.isDoing;
  }

  get error() {
    return this._getState().Auth.error;
  }

  _getState() {
    return Store.getInstance().state;
  }

  async logout() {
    this.refreshAuth();
  }

  async refreshAuth() {
    authActions.start();
    const isAuthed = false; 
    authActions.update(isAuthed);
    if (!isAuthed) {
      Store.getInstance().reset();  //clear all states and caches while logout
    }
  }

  checkAuth() {
    return false;
  }
}