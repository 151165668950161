import React from 'react';
import { Select } from 'antd';
import styles from './styles/MultiModelSelect.module.css';

const Option = Select.Option;

class MultiModelSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: this._isSingle() ? null : []
    }
  }

  _onChange = (value) => {
    this.setState({ 
      selectedValue: value
    });
  }

  _isSingle() {
    return this.props.mode == 'single';
  }

  _internalValue = () => {
    let { selectedValue } = this.state;
    if (this._isSingle()) {
      if (selectedValue) {
        return selectedValue;
      } else {
        return this.props.defaultValue;
      }
    } else {
      if (selectedValue.length === 0 && this.props.defaultValue) {
        selectedValue = this.props.defaultValue.map((value) => {
          return value.id.toString();
        });
      }
      return selectedValue;      
    }

  }

  get value() { 
    const selectedValue = this._internalValue();
    if (this._isSingle()) {
      return selectedValue;
    }

    const selectedModelList = selectedValue.map((key) => {
      for (let model of this.props.modelList) {
        if (model.id.toString() === key) {
          return model;
        }
      }
      return null;
    });

    return selectedModelList;
  }

  clear() {
    this.setState({
      selectedValue: this._isSingle() ? null : []
    });
  }

  render() {
    const model_opts = this.props.modelList.map((value) => {
      return (<Option key={value.id}>{value.name}</Option>);
    });

    const selectedValue = this._internalValue();

    let mode = this._isSingle() ? null : 'multiple';
    return (
      <Select
        className={styles.select}  
        mode={mode}
        placeholder={this.props.placeholder}
        value={selectedValue}
        ref={node => this.select = node}
        onChange={this._onChange}>
        {model_opts}
      </Select>
    );
  }
}

export default MultiModelSelect;
