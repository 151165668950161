import React from 'react';
import { connect } from 'react-redux';
import { Layout, Button, Form, Input, Icon, message } from 'antd';

import Assets from '@src/assets';
import Auth from '@src/model/Auth';
import styles from './styles/LoginPage.module.css';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      const { navigate } = this.props.navigation;
      navigate('BasicUser');
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let { isLoggedIn, isDoing, error } = nextProps;
    if (error != this.props.error) {
      if (error) {
        message.error(`登录错误：${error}`, 4);
      }
    }

    if (isLoggedIn) {
      const { navigate } = this.props.navigation;
      navigate('BasicUser');
    }

    return true;
  }

  _onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const {username, password} = values;
        Auth.getInstance().login(username, password);
      }
    });
  }

  render() {
    const { Content } = Layout;
    const { getFieldDecorator } = this.props.form;

    return (
      <Layout className={styles.layout}>
        <Content className={styles.content}>
          <div className={styles.divLogo}>
            <img className={styles.imgLogo} src={Assets.logo} alt="Bricks Admin"/>
            <span>Bricks Admin</span>
          </div>
          <Form onSubmit={this._onSubmit}>
            <Form.Item className={styles.itemForm}>
              {
                getFieldDecorator('username', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your username!'
                    }
                  ]
                })(<Input addonBefore={<Icon type="user"/>} placeholder="Username"/>)
              }
            </Form.Item>
            <Form.Item>
              {
                getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!'
                    }
                  ]
                })(<Input addonBefore={<Icon type="lock" />} type="password" placeholder="Password" />)
              }
            </Form.Item>
            <Form.Item className={styles.itemForm}>
              <Button type="primary" htmlType="submit" loading={this.props.isDoing} className={styles.btnSubmit}>
                登录
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    );
  }
}

function mapState2Props(state, props) {
  return {
    isLoggedIn: Auth.getInstance().isLoggedIn,
    isDoing: Auth.getInstance().isDoing,
    error: Auth.getInstance().error
  }
}

export default connect(mapState2Props)(Form.create({})(LoginPage));
