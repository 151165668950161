import React from 'react';
import { Switch } from 'antd';

class ClearSwitch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: null
    }
  }

  _onChangeSwitch = (checked) => {
    this.setState({ checked: checked });
  }

  get value() { 
    let { checked } = this.state;
    return checked || this.props.defaultChecked;
  }

  clear() {
    this.setState({
      checked: null
    });
  }

  render() {
    let value = this.state.checked || this.props.defaultChecked;

    return (
      <Switch
        className={this.props.className}
        checked={value}
        onChange={this._onChangeSwitch} />
    );
  }
}

export default ClearSwitch;
