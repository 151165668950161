import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {createAction, createReducer} from 'redux-act';

function payloadReducer(payload, meta) { return payload; }
function metaReducer(payload, meta) { return meta; }

export default function createActionsAndReducer(type, valueField, initialValue, isMultiple = false) {
  const actions = {
    fetchStart: createAction(`${type}/fetchStart`, payloadReducer, metaReducer),
    fetched: createAction(`${type}/fetched`, payloadReducer, metaReducer),
    updateStart: createAction(`${type}/updateStart`, payloadReducer, metaReducer),
    updated: createAction(`${type}/updated`, payloadReducer, metaReducer),
    failed: createAction(`${type}/failed`, payloadReducer, metaReducer),
    expired: createAction(`${type}/expired`, payloadReducer, metaReducer),
    reset: createAction(`${type}/reset`, payloadReducer, metaReducer)
  };

  const reducer = createReducer({
    [actions.fetchStart]: (state, payload, instanceId) => {
      if (isMultiple) {
        if (!instanceId) {
          return state;
        }

        let instanceState = state[instanceId];
        if (instanceState) {
          return {
            ...state, 
            [instanceId]: {...instanceState},
            isFetching: {[instanceId]:true},
            error: {[instanceId]:null}
          };
        } else {
          return {
            ...state, 
            [instanceId]: {[valueField]:initialValue, lastFetchTime: null},
            isFetching: {[instanceId]:true},
            isUpdating: {[instanceId]:false},
            error: {[instanceId]:null}
          };
        }
      } else {
        return {
          ...state,
          isFetching: true, 
          error: null
        };
      }
    },
    [actions.fetched]: (state, value, instanceId) => {
      if (isMultiple) {
        if (!instanceId) {
          return state;
        }

        return {
          ...state,
          [instanceId]: {[valueField]:value, lastFetchTime:new Date().getTime()},
          isFetching: {[instanceId]:false},
          error: {[instanceId]:null}
        };
      } else {
        return {
          ...state, 
          [valueField]: value, 
          lastFetchTime: new Date().getTime(), 
          isFetching: false, 
          error: null
        };
      }
    },
    [actions.updateStart]: (state, payload, instanceId) => {
      if (isMultiple) {
        if (!instanceId) {
          return state;
        }

        return {
          ...state,
          isUpdating: {[instanceId]:true},
          error: {[instanceId]:null}
        };
      } else {
        return {
          ...state, 
          isUpdating: true, 
          error: null
        };
      }
    },
    [actions.updated]: (state, value, instanceId) => {
      if (isMultiple) {
        if (!instanceId) {
          return state;
        }

        let instanceState = state[instanceId];
        if (value) {
          return {
            ...state, 
            [instanceId]: {...instanceState, [valueField]: value},
            isUpdating: {[instanceId]:false},
            isFetching: {[instanceId]:false},
            error: {[instanceId]:null}
          };  
        } else {  //with no value, then expire
          return {
            ...state,
            [instanceId]: {...instanceState, lastFetchTime: null},
            isUpdating: {[instanceId]:false},
            isFetching: {[instanceId]:false},
            error: {[instanceId]:null}
          };
        }
      } else {
        if (value) {
          return {
            ...state,
            [valueField]: value, 
            isUpdating:false,
            isFetching:false,
            error:null
          };
        } else {  //with no value, then expire
          return {
            ...state, 
            lastFetchTime: null,
            isUpdating: false, 
            isFetching: false, 
            error: null
          };
        }
      }
    },
    [actions.failed]: (state, error, instanceId) => {
      if (isMultiple) {
        if (!instanceId) {
          return state;
        }

        let instanceState = state[instanceId];
        return {
          ...state, 
          isUpdating: {[instanceId]:false},
          isFetching: {[instanceId]:false},
          error: {[instanceId]:error}
        };
      } else {
        return {
          ...state, 
          isUpdating: false, 
          isFetching: false, 
          error: error
        };
      }
    },
    [actions.expired]: (state, payload, instanceId) => {
      if (isMultiple) {
        if (!instanceId) {
          return state;
        }

        let instanceState = state[instanceId];
        return {
          ...state,
          [instanceId]: {...instanceState, lastFetchTime:null}
        };
      } else {
        return {
          ...state, 
          lastFetchTime: null
        };
      }
    },
    [actions.reset]: (state, payload, instanceId) => {
      return isMultiple ? {isFetching:{}, isUpdating:{}, error:{}} : {[valueField]: initialValue, lastFetchTime: null};
    }
  }, isMultiple ? {isFetching:{}, isUpdating:{}, error:{}} : {[valueField]: initialValue, lastFetchTime: null});

  const persistConfig = {
    key: type,
    storage: storage,
    blacklist: ['isFetching', 'isUpdating', 'error']
  };

  return {
    actions: actions,
    reducer: persistReducer(persistConfig, reducer),
    initialValue: initialValue
  };
}