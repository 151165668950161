import React from 'react';
import { connect } from 'react-redux';
import { Layout, Button, Avatar, message } from 'antd';

import { ReactComponent, MainLayout } from '@src/components';

import stylesLayout from '@src/components/styles/MainLayout.module.css';
import styles from './styles/PhotoUploadPage.module.css';

const { Content } = Layout;

class PhotoUploadPage extends ReactComponent {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  
  componentWillMount() {
    // const { apiList, roleList } = this.props.model;
    // apiList.fetchIfExpired(true);
  }

  render() {  
    return (
      <MainLayout {...this.props}>
        <Content className={stylesLayout.content}>
          <div>333</div>
        </Content>
      </MainLayout>
    );
  }
}

function mapState2Props(state, props) {
  return {
    value: {
    },
    model: {
    }
  }
}

export default connect(mapState2Props)(PhotoUploadPage);