import configureStore from './store/configureStore';

export default class Store {
  static _instance;
  static getInstance() {
    if (!this._instance) {
      this._instance = new Store();
    }
    return this._instance;
  }

  constructor() {
    this.resetActs = [];
  }

  init(models, initialState, onComplete) {
    let actionMap = [];
    let reducers = {};

    for (let key in models) {
      let modelClz = models[key];
      let type = modelClz.type;
      let reduxInfo = modelClz.getReduxInfo();
      actionMap.push(reduxInfo.actions);
      reducers[type] = reduxInfo.reducer;

      if (reduxInfo.actions.reset) {
        if (!modelClz.isWhiteInReset) {
          this.resetActs.push(reduxInfo.actions.reset);
        }
      }
    }

    let {store, persistor} = configureStore(actionMap, reducers, initialState, onComplete);
    this._store = store;
    this._persistor = persistor;
  }

  get store() {
    return this._store;
  }

  get state() {
    return this._store.getState();
  }

  reset() {
    for (let resetAct of this.resetActs) {
      resetAct();
    }
  }
}